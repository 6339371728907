import React from "react";
import Select from "react-select";

interface Props {
  id: string;
  placeholder: any;
  onChange: (newValue: any, actionMeta: any) => void;
  value: string;
}

const Dropdown: React.FC<Props> = ({ id, placeholder, onChange, value }) => {
  return (
    <Select
      id={id}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      styles={{
        control: (base) => ({
          ...base,
          border: "2.5px #ff8559 solid !important",
          borderRadius: "9px",
          width: "100%",
          boxShadow: "0px 3px 6px #00000029",
        }),
        container: (base) => ({
          ...base,
          width: "100%",
        }),
        indicatorsContainer: (base) => ({
          ...base,
          backgroundColor: "#ff8559",
          color: "#fff",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          backgroundColor: "#ff8559",
          color: "#fff",
        }),
        indicatorSeparator: (base) => ({
          display: "none",
        }),
      }}
    ></Select>
  );
};

export default Dropdown;
