import Style from "./Order.module.css";

interface Props {
  title: string;
  color: string;
  imageSrc: string;
  imageAlt: string;
}

const OrderStep: React.FC<Props> = ({ title, color, imageSrc, imageAlt }) => {
  return (
    <div className={Style["order-step"]} style={{ background: color }}>
      <h3>{title}</h3>
      <img src={imageSrc} alt={imageAlt} />
    </div>
  );
};

export default OrderStep;
