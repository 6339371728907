import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "./Dropdown";

interface Props {
  id: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const SearchInput: React.FC<Props> = ({ id, onChange, value }) => {
  return (
    <Dropdown
      id={id}
      placeholder={
        <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faLocation} />{" "}
          Search location...
        </span>
      }
      onChange={onChange}
      value={value}
    />
  );
};

export default SearchInput;
