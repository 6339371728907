import ReservationStep from "./ReservationStep";

import Meals from "../../../icons/Meals.svg";
import NearLocation from "../../../icons/NearLocation.svg";
import Reserve from "../../../icons/Reserve.svg";
import YourLocation from "../../../icons/YourLocation.svg";

import Style from "./Reservation.module.css";

const ReservationSection: React.FC = () => {
  return (
    <div className={Style["reservation-section"]}>
      <div className={Style["reservation-container"]}>
        <div className={Style["row"]}>
          <ReservationStep
            icon={YourLocation}
            title="Изберете град"
            description="Въведете желаното местоположение"
          />
          <ReservationStep
            icon={NearLocation}
            title="Изберете ресторант"
            description="Разгледайте ресторантите в избрания от Вас град"
          />
        </div>
        <div className={Style["row"]}>
          <ReservationStep
            icon={Meals}
            title="Разгледайте менюто"
            description="Потърсете нещо апетитно"
          />
          <ReservationStep
            icon={Reserve}
            title="Направете резервация"
            description="Запазете маса в желания от Вас ресторант"
          />
        </div>
      </div>
    </div>
  );
};

export default ReservationSection;
