import { useEffect, useState } from "react";

const useScreenSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return {
    isExtraSmallSize: width <= 576, // Mobile phones
    isSmallSize: width < 768, // Mobile phones and small sized tablets
    isMediumSize: width >= 768 && width <= 992, // Tablets
    isLargeSize: width > 992, // Desktops
  };
};

export default useScreenSize;
