import Style from "./About.module.css";

interface Props {
  title: string;
  text: string;
  imageSrc: string;
  imageAlt: string;
}

const AboutItem: React.FC<Props> = ({ title, text, imageSrc, imageAlt }) => {
  return (
    <div className={Style["about-item"]}>
      <img src={imageSrc} alt={imageAlt} />
      <div className={Style["stroke"]}></div>
      <div>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default AboutItem;
