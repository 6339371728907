import ContactsForm from "./ContactsForm";
import ContactsItem from "./ContactsItem";
import { faPhoneVolume, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import Style from "./Contacts.module.css";

const ContactsSection: React.FC = () => {
  return (
    <div className={Style["contacts-section"]}>
      <div className={Style["contacts-details"]}>
        <div>
          <div>
            <h1>Искате ли да научите повече?</h1>
            <p>
              Нашият екип е винаги отворен за Вашите въпроси за това как може да
              помогнем на бизнеса Ви
            </p>
          </div>
          <ContactsItem
            title="Пишете ни на E-mail:"
            icon={faEnvelope}
            iconColor="#fff"
            text="contact@warenex.com"
          />
          <ContactsItem
            title="Или се обадете на телефон:"
            icon={faPhoneVolume}
            iconColor="#fff"
            text="+359 87 731 0641"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactsSection;
