import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Style from "./Contacts.module.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  title: string;
  icon: IconProp;
  iconColor: string;
  text: string;
}

const ContactsItem: React.FC<Props> = ({ title, icon, text, iconColor }) => {
  return (
    <div className={Style["contacts-item"]}>
      <h3>{title}</h3>
      <FontAwesomeIcon icon={icon} color={iconColor} size="2x" />
      <p>{text}</p>
    </div>
  );
};

export default ContactsItem;
