import useScreenSize from "../../../hooks/useScreenSize";
import Style from "./Business.module.css";

const BusinessHeader: React.FC = () => {
  const { isExtraSmallSize } = useScreenSize();

  const imageSrc = isExtraSmallSize
    ? "/main_business_mobile.png"
    : "/main_business.png";
  return (
    <div className={Style["business-header"]}>
      <div>
        <h1>Подобрете цялостното изживяване във Вашия ресторант</h1>
        <img src={imageSrc} alt="Application screens." />
        <p>Бъдете крачка пред останалите</p>
      </div>
    </div>
  );
};

export default BusinessHeader;
