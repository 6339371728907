import { Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout/Layout";
import Business from "./pages/Business";

function App() {
  return (
    <Layout>
      <Route exact path={["/", "/home"]} component={Home} />
      <Route exact path="/business" component={Business} />
    </Layout>
  );
}

export default App;
