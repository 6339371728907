import { useEffect } from "react";
import AboutSection from "../components/Business/About/AboutSection";
import ContactsSection from "../components/Business/Contacts/ContactsSection";
import BusinessHeader from "../components/Business/Header/BusinessHeader";

const Business: React.FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <div>
      <BusinessHeader />
      <AboutSection />
      <ContactsSection />
    </div>
  );
};

export default Business;
