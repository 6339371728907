import SearchInput from "../../UI/SearchInput";

import Style from "./Search.module.css";

const SearchForm: React.FC = () => {
  return (
    <form className={Style["search-form"]}>
      <SearchInput id="searchInput" onChange={() => {}} value="" />
      <br />
    </form>
  );
};

export default SearchForm;
