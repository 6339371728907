import { useEffect } from "react";

import OrderSection from "../components/Home/Order/OrderSection";
import SearchSection from "../components/Home/Search/SearchSection";
import ReservationSection from "../components/Home/Reservation/ReservationSection";

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <div>
      <SearchSection />
      <ReservationSection />
      <OrderSection />
    </div>
  );
};

export default Home;
