import Style from "./About.module.css";
import AboutItem from "./AboutItem";

import Orders from "../../../icons/Orders.svg";
import Menu from "../../../icons/Menu.svg";
import Reservation from "../../../icons/Reservation.svg";
import Staff from "../../../icons/Staff.svg";

const AboutUsSection: React.FC = () => {
  return (
    <div className={Style["about-section"]}>
      <div className={Style["about-text-container"]}>
        <h1>Какво предлага Smart Foodie</h1>
        <p>
          Smart Foodie е лесна за използване дигитална платформа за ресторанти,
          която улеснява работата на служителите и подобрява изживяването на
          клиентите
        </p>
      </div>
      <div className={Style["dotted-grid"]}>
        <AboutItem
          title="Лесни поръчки"
          text="Поръчките се създават директно от телефона"
          imageSrc={Orders}
          imageAlt="Fast orders."
        />
        <AboutItem
          title="Дигитално меню"
          text="Удобно за разглеждане както в ресторанта, така и от вкъщи"
          imageSrc={Menu}
          imageAlt="Easy menu."
        />
        <AboutItem
          title="Онлайн резервация"
          text="Резервирането на маса става за секунди"
          imageSrc={Reservation}
          imageAlt="Online reservations."
        />
        <AboutItem
          title="По-добра работа на персонала"
          text="Улеснява работните процеси и подобрява обслужването"
          imageSrc={Staff}
          imageAlt="Better performing staff."
        />
      </div>
    </div>
  );
};

export default AboutUsSection;
