import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faBuilding } from "@fortawesome/free-solid-svg-icons";
import Style from "./Header.module.css";
import useScreenSize from "../../hooks/useScreenSize";
import { slide as Menu } from "react-burger-menu";

import "./HamburgerMenu.css";

const Header = () => {
  const { isMediumSize, isSmallSize } = useScreenSize();

  const [open, setOpen] = useState(false);

  const showHamburgerMenu = isMediumSize || isSmallSize;

  const desktopNavigation = (
    <nav className={Style["nav"]}>
      <NavLink
        className={Style["nav-link"]}
        exact
        to="/"
        activeClassName={Style["active"]}
      >
        {" "}
        <span>Начало</span>
      </NavLink>
      <NavLink
        className={Style["nav-link"]}
        exact
        to="/business"
        activeClassName={Style["active"]}
      >
        {" "}
        <span>
          <FontAwesomeIcon
            icon={faBriefcase}
            color="#fff"
            style={{ marginRight: "5px" }}
          />
          Стани наш партньор
        </span>
      </NavLink>
    </nav>
  );

  const mobileViewNavigation = (
    <Menu
      right
      isOpen={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <NavLink onClick={() => setOpen(false)} to="/">
        Начало
      </NavLink>
      <NavLink onClick={() => setOpen(false)} to="/business">
        <span>Стани партньор</span>
      </NavLink>
    </Menu>
  );
  return (
    <header>
      <div className={Style["nav-bar"]}>
        <div className={Style["nav-logo"]}>
          <Link to="/">
            <img id="logo" alt="Logo" src="logo_white.png"></img>
          </Link>
        </div>
        {showHamburgerMenu ? mobileViewNavigation : desktopNavigation}
      </div>
    </header>
  );
};

export default Header;
