import useScreenSize from "../../../hooks/useScreenSize";
import WavesMobile from "../../UI/WavesMobile";
import WavesDesktop from "../../UI/WavesDesktop";
import Style from "./Search.module.css";
import SearchForm from "./SearchForm";

const SearchSection: React.FC = () => {
  const { isSmallSize, isMediumSize } = useScreenSize();
  const isMobileView = isSmallSize || isMediumSize;
  return (
    <div className={Style["search-section"]}>
      <div className={Style["search-image-text-container"]}>
        <div className={Style["search-text-container"]}>
          <div className={Style["search-text-section"]}>
            <h1>Чудиш се къде да хапнеш нещо вкусно?</h1>
            <p>
              Разгледай менютата на ресторантите и направи своята резервация
              бързо и лесно.
            </p>
          </div>
          {isMobileView && <SearchForm />}
        </div>
        <div className={Style["food-img-container"]}>
          <img
            className={Style["food-img"]}
            src="/hamburger.webp"
            alt="Food."
          />
        </div>
      </div>

      {!isMobileView && <SearchForm />}
      {!isMobileView ? <WavesDesktop /> : <WavesMobile />}
    </div>
  );
};

export default SearchSection;
