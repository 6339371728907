import useScreenSize from "../../hooks/useScreenSize";
import Style from "./Waves.module.css";

const WavesMobile: React.FC = () => {
  const { isExtraSmallSize } = useScreenSize();
  return (
    <div className={Style["container"]}>
      <div className={Style["wave"]}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 1440 ${isExtraSmallSize ? 1000 : 850}`}
        >
          <defs>
            <linearGradient id="wave1-gradient">
              <stop offset="20%" stop-opacity="0.3" stop-color="#FF895E" />
              <stop offset="100%" stop-opacity="0.3" stop-color="#FF895E" />
            </linearGradient>
          </defs>
          <path
            id="wave1-path"
            d="M0,480L48,469.3C96,459,192,437,288,448C384,459,480,501,576,506.7C672,512,768,480,864,469.3C960,459,1056,501,1152,522.7C1248,544,1344,544,1392,544L1440,544L1440,1000L1392,1000C1344,1000,1248,1000,1152,1000C1056,1000,960,1000,864,1000C768,1000,672,1000,576,1000C480,1000,384,1000,288,1000C192,1000,96,1000,48,1000L0,1000Z"
            fill="url(#wave1-gradient)"
          ></path>
        </svg>
      </div>
      <div className={Style["wave"]}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 1440 ${isExtraSmallSize ? 1000 : 850}`}
        >
          <defs>
            <linearGradient id="wave2-gradient">
              <stop offset="0%" stop-color="#FF895E" />
              <stop offset="50%" stop-color="#FFC081" />
            </linearGradient>
          </defs>
          <path
            id="wave2-path"
            d="M0,480L48,480C96,480,192,480,288,469.3C384,459,480,437,576,437.3C672,437,768,459,864,458.7C960,459,1056,437,1152,437.3C1248,437,1344,459,1392,469.3L1440,480L1440,1000L1392,1000C1344,1000,1248,1000,1152,1000C1056,1000,960,1000,864,1000C768,1000,672,1000,576,1000C480,1000,384,1000,288,1000C192,1000,96,1000,48,1000L0,1000Z"
            fill="url(#wave2-gradient)"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default WavesMobile;
