import Footer from "./Footer";
import Header from "./Header";

import Style from "./Layout.module.css";

const Layout = ({ children }: any) => {
  return (
    <div className={Style.layout}>
      <Header />
      <main className={Style.main}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
