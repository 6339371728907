import Style from "./Order.module.css";
import OrderStep from "./OrderStep";

const OrderSection: React.FC = () => {
  return (
    <>
      <div className={Style["order-section"]}>
        <div>
          <h1>Направете поръчка в ресторанта</h1>
        </div>
        <img src="/phone_menu.png" alt="Make order from your phone." />
        <div>
          <h1>от вашето мобилно устройство бързо и лесно</h1>
        </div>
      </div>
      <div className={Style["order-steps"]}>
        <div className={Style["order-steps-inner-container"]}>
          <h1>Как става това?</h1>
          <div>
            <OrderStep
              title="Сканирайте QR кода на масата"
              color="transparent linear-gradient(180deg, #E87248 0%, #FFE3D9 100%) 0% 0% no-repeat padding-box"
              imageSrc="/phone_home.png"
              imageAlt="Home screen of the mobile app."
            />
            <OrderStep
              title="Изберете храна или напитка от менюто"
              color="transparent linear-gradient(180deg, #FF8559 0%, #FFE2D6 100%) 0% 0% no-repeat padding-box"
              imageSrc="/phone_menu.png"
              imageAlt="Menu screen of the mobile app."
            />
            <OrderStep
              title="Направете своята поръчка"
              color="transparent linear-gradient(180deg, #FFC081 0%, #FFE5CC 100%) 0% 0% no-repeat padding-box"
              imageSrc="/phone_order.png"
              imageAlt="Place order screen of the mobile app."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSection;
