import useScreenSize from "../../hooks/useScreenSize";
import Style from "./Waves.module.css";

const WavesDesktop: React.FC = () => {
  const { isExtraSmallSize } = useScreenSize();
  return (
    <div className={Style["container"]}>
      <div className={Style["wave"]}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 1440 ${isExtraSmallSize ? 400 : 340}`}
        >
          <defs>
            <linearGradient id="wave1-gradient">
              <stop offset="20%" stop-opacity="0.3" stop-color="#FF895E" />
              <stop offset="100%" stop-opacity="0.3" stop-color="#FF895E" />
            </linearGradient>
          </defs>
          <path
            id="wave1-path"
            d="M0,192L48,181.3C96,171,192,149,288,160C384,171,480,213,576,218.7C672,224,768,192,864,181.3C960,171,1056,213,1152,234.7C1248,256,1344,256,1392,256L1440,256L1440,400L1392,400C1344,400,1248,400,1152,400C1056,400,960,400,864,400C768,400,672,400,576,400C480,400,384,400,288,400C192,400,96,400,48,400L0,400Z"
            fill="url(#wave1-gradient)"
          ></path>
        </svg>
      </div>
      <div className={Style["wave"]}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 1440 ${isExtraSmallSize ? 400 : 340}`}
        >
          <defs>
            <linearGradient id="wave2-gradient">
              <stop offset="0%" stop-color="#FF895E" />
              <stop offset="50%" stop-color="#FFC081" />
            </linearGradient>
          </defs>
          <path
            id="wave2-path"
            d="M0,224L48,224C96,224,192,224,288,213.3C384,203,480,181,576,181.3C672,181,768,203,864,202.7C960,203,1056,181,1152,181.3C1248,181,1344,203,1392,213.3L1440,224L1440,400L1392,400C1344,400,1248,400,1152,400C1056,400,960,400,864,400C768,400,672,400,576,400C480,400,384,400,288,400C192,400,96,400,48,400L0,400Z"
            fill="url(#wave2-gradient)"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default WavesDesktop;
