import Style from "./Reservation.module.css";

interface Props {
  icon: string;
  title: string;
  description: string;
}

const ReservationStep: React.FC<Props> = ({ icon, title, description }) => {
  return (
    <div className={Style["reservation-step"]}>
      <img src={icon} alt="Step icon." />
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ReservationStep;
